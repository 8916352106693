import { TEXT_INPUT, TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    telesign_customer_id: {
      type: TEXT_INPUT,
      label: 'Telesign Customer ID',
      rules: 'required',
      placeholder: 'Type...',
    },
    telesign_api_key: {
      type: TEXTAREA_INPUT,
      label: 'Telesign API Key',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  return {
    fields,
  }
}
