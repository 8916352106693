<template>
  <integration-form
    ref="formRef"
    :set="setTelesign"
    :get="getTelesign"
    :fields="fields"
    title="Telesign"
  />
</template>

<script>
import { mapActions } from 'vuex'
import config from './config'
import mixin from '../mixin'

export default {
  name: 'Telesign',
  mixins: [mixin],
  methods: {
    ...mapActions('settingsIntegrations', ['getTelesign', 'setTelesign']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>

<style scoped>

</style>
